import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, Pagination, Navigation, SwiperOptions } from "swiper";

import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/free-mode";

// import arrowBack from "../public/assets/arrowBack.svg";
// import arrowNext from "../public/assets/arrowNext.svg";

export default function Swipe1({ imgs, delay }) {
  const [currentPage, setCurrentPage] = useState(1);
  // const [arrow, setArrow] = useState(arrowNext);

  const handleSlideChange = swiper => {
    setCurrentPage(swiper.realIndex + 1);
  };

  const [swiper, setSwiper] = useState(true);
  const handleClick = e => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;

    if (x < rect.width / 2) {
      swiper.slidePrev();
      // setArrow(arrowBack);
    } else {
      swiper.slideNext();
      // setArrow(arrowNext);
    }
  };

  return (
    <>
      <div onClick={handleClick}>
        <Swiper
          onSwiper={setSwiper}
          onSlideChange={handleSlideChange}
          spaceBetween={0}
          loop={true}
          // navigation={true}

          navigation={{
            // nextEl: arrowNext,
            // prevEl: arrowBack,
            disabledClass: "swiper-button-disabled",
          }}
          centeredSlides={true}
          autoplay={{
            delay: delay,
          }}
          pagination={{
            clickable: true,
            type: "fraction",
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {imgs.map(img => (
            <SwiperSlide key={img}>
              <img src={"/assets/" + img} alt={img} onClick={handleClick} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* <div className="mySwiperP"> */}
      {currentPage === 1 ? (
        <p>
          Disegno con cera calda e pennelli.
          <br />
          <span className="opa">Drawing with hot wax and brushes.</span>
        </p>
      ) : null}

      {currentPage === 2 ? (
        <p>
          Utilizzo di “cantings” per disegni dettagliati.
          <br />
          <span className="opa">Use of “cantings” for detailed drawings. </span>
        </p>
      ) : null}

      {currentPage === 3 ? (
        <p>
          Preparazione tintura naturale, per esempio legno di campeggio.
          <br />
          <span className="opa">
            Preparation of natural dye, such as logwood.
          </span>
        </p>
      ) : null}

      {currentPage === 4 ? (
        <p>
          Ripetuta immersione del batik nel colore indaco.
          <br />
          <span className="opa">
            Repeated immersion of the batik in indigo dye.
          </span>
        </p>
      ) : null}

      {currentPage === 5 ? (
        <p>
          Asciugatura prima di un altro strato di cera.
          <br />
          <span className="opa">Drying before another layer of wax. </span>
        </p>
      ) : null}

      {currentPage === 6 ? (
        <p>
          Cura del dettaglio, orlo fatto a mano.
          <br />
          <span className="opa">Attention to detail, hand-made hem. </span>
        </p>
      ) : null}

      {currentPage === 7 ? (
        <p>
          Double face “Voglia di leggerezza” ... papaveri” shantung di seta.
          <br />
          <span className="opa">
            Double face “Want of lightness” ... poppies” shantung silk.
          </span>
        </p>
      ) : null}

      {currentPage === 8 ? (
        <p>
          Double face “Voglia di leggerezza” ... soffioni” shantung di seta.
          <br />
          <span className="opa">
            Double face '”Want of lightness” ... dandelions' shantung silk.
          </span>
        </p>
      ) : null}

      {currentPage === 9 ? (
        <p>
          “Frida Kahlo” habotai di seta con applicazioni in argento e
          madreperla.
          <br />
          <span className="opa">
            “Frida Kahlo” silk habotai with silver and nacre appliqués.
          </span>
        </p>
      ) : null}

      {currentPage === 10 ? (
        <p>
          “Palm Spring” twill di seta 140x140 cm. <br />
          <span className="opa">“Palm Spring” twill silk 140x140 cm.</span>
        </p>
      ) : null}

      {currentPage === 11 ? (
        <p>
          “Affetto zebrato” crepe de chine 140x140 cm. <br />
          <span className="opa">
            “Zebra affection” crepe de chine 140x140 cm.
          </span>
        </p>
      ) : null}

      {currentPage === 12 ? (
        <p>
          “Cascata nottura” batik su seta 140x140 cm. <br />
          <span className="opa">
            “Night waterfall” batik on silk 140x140 cm.
          </span>
        </p>
      ) : null}
    </>
  );
}
