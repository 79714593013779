import React, { useState } from "react";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay, Pagination, Navigation, SwiperOptions } from "swiper";

import "swiper/css";

import "swiper/css/navigation";
import "swiper/css/free-mode";

export default function Swiper2({ imgs, delay }) {
  const [currentPage, setCurrentPage] = useState(1);

  function handleSlideChange(swiper) {
    setCurrentPage(swiper.realIndex + 1);
  }

  return (
    <>
      <Swiper
        onSlideChange={handleSlideChange}
        spaceBetween={0}
        loop={true}
        navigation={true}
        centeredSlides={true}
        autoplay={{
          delay: delay,
        }}
        pagination={{
          clickable: true,
          type: "fraction",
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {imgs.map(img => (
          <SwiperSlide key={img}>
            <img src={"/assets/" + img} alt={img} />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* <div className="mySwiperP"> */}
      {currentPage === 1 ? (
        <p>
          Artista tessile con specializzazione della tecnica del Batik. <br />
          <span className="opa">
            Textile artist with specialisation in the Batik technique.
          </span>
        </p>
      ) : null}

      {currentPage === 2 ? (
        <p>
          In atelier circondata da alcune mie creazioni. <br />
          <span className="opa">
            In the atelier surrounded by some of my creations.
          </span>
        </p>
      ) : null}

      {currentPage === 3 ? (
        <p>
          Scelte qualitative nell’uso di seta pura. <br />
          <span className="opa">Quality choices in the use of pure silk.</span>
        </p>
      ) : null}

      {currentPage === 4 ? (
        <p>
          Nello studio stilistico di Hong Kong - 1987. <br />
          <span className="opa"> In the Hong Kong design studio - 1987. </span>
        </p>
      ) : null}

      {currentPage === 5 ? (
        <p>
          Giava, tutto pronto per sperimentare il Batik - 2014.
          <br />
          <span className="opa">
            Java, all set for experiencing Batik - 2014.
          </span>
        </p>
      ) : null}

      {currentPage === 6 ? (
        <p>
          Yogyakarta - Batik in lavorazione - 2014. <br />
          <span className="opa">Yogyakarta - Batik in progress - 2014. </span>
        </p>
      ) : null}

      {currentPage === 7 ? (
        <p>
          Bali - "Ragazza indonesiana al fiume" - 2014. <br />
          <span className="opa">
            Bali - "Indonesian girl at the river" - 2014.
          </span>
        </p>
      ) : null}

      {currentPage === 8 ? (
        <p>
          Tra le suore Monastero Orsaminore - Orselina - 2020.
          <br />
          <span className="opa">
            Among the nuns of the Monastery Orsaminore - Orselina - 2020.
          </span>
        </p>
      ) : null}

      {currentPage === 9 ? (
        <p>
          Monastero Orsaminore - Orselina "Miracolo dei pesci"- 2016.
          <br />
          <span className="opa">
            Orsaminore Monastery - Orselina "Miracle of fish"- 2016.
          </span>
        </p>
      ) : null}

      {currentPage === 10 ? (
        <p>
          Esposizione Arti&#60;&#62;sta - Monza "Know the Batik" - 2019.
          <br />
          <span className="opa">
            Arti&#60;&#62;sta Exhibition - Monza "Know the Batik" - 2019.
          </span>
        </p>
      ) : null}

      {currentPage === 11 ? (
        <p>
          Esposizione La Filanda - Mendrisio "Passione tessile" - 2019.
          <br />
          <span className="opa">
            Exhibition La Filanda - Mendrisio "Textile passion" - 2019.
          </span>
        </p>
      ) : null}

      {currentPage === 12 ? (
        <p>
          Esposizione FATart - Sciaffusa "Women*in arts" - 2020.
          <br />
          <span className="opa">
            Exhibition FATart - Shaffhausen "Women*in arts" - 2020.
          </span>
        </p>
      ) : null}

      {currentPage === 13 ? (
        <p>
          Hotel Bigatt - Lugano "Seta" - 2021.
          <br />
          <span className="opa">Hotel Bigatt - Lugano "Seta" - 2021.</span>
        </p>
      ) : null}
    </>
  );
}
