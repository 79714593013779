import { createClient } from "@sanity/client";

export default createClient({
  projectId: "hidccc6x", // find this at manage.sanity.io or in your sanity.json
  dataset: "production", // this is from those question during 'sanity init'
  apiVersion: "2023-04-25",
  token:
    "sktScxETIU8UvnQLDecJ55vr6Uvcm83lbiuiXTBgp642XmiPCRHTSfjUtfxLr1FCp7TrUWhpK3jpxuwC64VKkruYRysahBxZDl7nv2vGsTzQNAoMv9T2wk4fngX6WxzgsH9yPJHkRPO5UHPqUv4iz1LMJzgnmlyK2IwQYF7QpOLtbpDgPw5I",
  useCdn: false,
});
