import Swipe from "./Swiper2";
import sanityClient from "./sanityClient";
import { useEffect, useState } from "react";
import { PortableText } from "@portabletext/react";
import Swipe1 from "./Swiper1";
import Swiper2 from "./Swiper2";
import VideoPlayer from "./VideoPlayer";
// import logo from "./assets/LogoIKW.svg";

// import hand from "./assets/hand.png";

function App() {
  const [homeContent, setHomeContent] = useState();
  const [loaded, setLoaded] = useState(false);
  async function fetchData() {
    const result = await sanityClient.fetch(`*[_type == "homePage"][0]`);
    setHomeContent(result);
    setLoaded(true);
  }

  useEffect(() => {
    fetchData();
  }, [loaded]);

  window.addEventListener("scroll", function () {
    // Get the section with the ID 'info'
    const infoSection = document.querySelector("#info");

    // Get the distance between the top of the section and the top of the document
    const infoSectionTop = infoSection.offsetTop;

    // Get the height of the window
    const windowHeight = window.innerHeight;

    // Get the distance between the top of the document and the bottom of the window
    const windowBottom = window.pageYOffset + windowHeight;

    // Get the distance between the top of the document and the top of the window
    const windowTop = window.pageYOffset;

    // Check if the bottom of the window has reached the top of the section
    if (windowBottom >= infoSectionTop) {
      // Bottom of the window has reached the section with ID 'info'
      document.body.style.backgroundColor = "#172558";
      document.body.style.color = "#f0e4d4";
    } else if (windowTop < infoSectionTop) {
      // Top of the window is above the section with ID 'info'
      document.body.style.backgroundColor = "#f0e4d4";
      document.body.style.color = "#172558";
    }
  });

  return (
    <div className="App">
      <div className="content">
        <div className="gradient">
          <section>
            <nav>
              <img src={"./assets/LogoIKW.svg"} className="logo" />
              <a target="_blank" href="https://ikw.sumupstore.com/prodotti">
                ONLINE SHOP
              </a>
            </nav>
            <h1>TEXTILE BATIK ARTWORKS</h1>

            <video
              src="./assets/video.mp4"
              autoPlay
              muted
              loop
              controls={false}
              playsInline={true}
            ></video>

            {/* <VideoPlayer /> */}
          </section>
          <section>
            <h2>BATIK</h2>

            <div className="ps">
              <p>
                Il batik è un’antica tecnica riconosciuta nel 2009 dall’Unesco
                come Capolavoro del Patrimonio Orale e Immateriale dell’Umanità.
                <br></br> <br></br>
                Con l’utilizzo di “cantings”, pennelli o stampi si applica la
                cera calda sul disegno in modo da impermeabilizzare il tessuto.
                Si prosegue con la colorazione immergendo il lavoro in un bagno
                di colore, passando dai toni chiari a quelli più scuri. Vari
                strati di cera si alternano a molteplici colorazioni, ottenendo
                effetti speciali particolari e imprevedibili. Tolta la cera, è
                sorprendente vedere la ricchezza di texture e l’effetto cracklé
                che si ottiene. Ogni pezzo è unico e irrepetibile, a volte
                arricchito con ricami e applicazioni.
                <br></br> <br></br>È un lavoro di grande concentrazione e
                pazienza.
              </p>

              <p>
                Batik is an ancient technique, recognized by Unesco since 2009
                as a Masterpiece of Oral and Intangible Heritage Humanity.
                <br></br> <br></br>
                Brushing or drawing hot wax, with “cantings”, brushes or stamps,
                blocks selected areas of textile out. The fabric is then dyed,
                starting with the lightest tones gradually building up through
                to the darkest. Various wax layers alternating with multiple
                colors, obtaining special details and elaborated designs. At the
                end the wax is eliminated. It is surprisingly to see the
                richness of texture and the crackle effect you get. Each piece
                is unique and unrepeatable, sometimes embellished with
                embroidery and applications.
                <br></br> <br></br>It is a work of great concentration and
                patience.
              </p>
            </div>

            <div className="swiper">
              <Swipe1
                imgs={[
                  "01_IKW_Batik.webp",
                  "02_IKW_Batik.webp",
                  "03_IKW_Batik.webp",
                  "04_IKW_Batik.webp",
                  "05_IKW_Batik.webp",
                  "06_IKW_Batik.webp",
                  "07_IKW_Batik.webp",
                  "08_IKW_Batik.webp",
                  "09_IKW_Batik.webp",
                  "10_IKW_Batik.webp",
                  "11_IKW_Batik.webp",
                  "12_IKW_Batik.webp",
                ]}
                delay={4500}
              />
            </div>
          </section>
          <section>
            <h2>Biografia</h2>

            <div className="ps">
              <p>
                Di formazione sono stilista e disegnatrice di tessuti. Ho
                lavorato nel mondo internazionale della moda per dieci anni tra
                Lugano, Ginevra e Hong Kong. <br></br> <br></br> La passione e
                la curiosità mi portano a scoprire nuove culture; tradizioni che
                esprimo nei lavori che realizzo. <br></br> <br></br> Nel 2014
                viaggio in Indonesia, sulle isole di Giava e Bali, per seguire,
                presso artisti locali, dei workshop di batik tradizionale.
                <br></br> <br></br>
                Quest’antica tecnica mi affascina sia per l’esito finale, sia
                per il percorso che richiede tempo, concentrazione e pazienza.
                Un cammino meditativo; uno stile a me congeniale che mi porta a
                realizzare esclusive creazioni artistiche senza tempo. <br></br>
                <br></br>Una strada in continua evoluzione, dalla ricerca di
                tinture naturali, all'accostamento di altre tecniche, come per
                esempio quella giapponese dello “shibori” o dell’“ecoprint”.
                <br></br> <br></br> La collaborazione con le suore del monastero
                Orsaminore di Orselina, dove realizzo dei preziosi batik su seta
                filata e tessuta a mano, arricchisce la qualità e la sensibilità
                d’esecuzione delle mie creazioni. <br></br> <br></br> Nel mio
                atelier a Rancate creo pezzi unici ed eseguo lavori su
                richiesta. <br></br> <br></br> Propongo quest’affascinante
                tecnica attraverso corsi, per principianti, a docenti di
                attività creative, ad allievi di scuole superiori, sia nel mio
                Atelier, sia in spazi quali musei o nell’ambito di eventi
                artistici.
              </p>

              <p>
                Trained as a stylist and textile designer, I worked in the
                international fashion world for ten years between Lugano, Geneva
                and Hong Kong. <br></br> <br></br> My passion and curiosity led
                me to discover new cultures; traditions that I express in the
                works I create. <br></br> <br></br> In 2014 I travelled to
                Indonesia, to the islands of Java and Bali, to attend
                traditional batik workshops with local artists. <br></br>
                <br></br> This ancient technique fascinates me both for the
                final result and for its process that requires time,
                concentration and patience. A meditative journey; a style that
                suits me and which leads me to realise exclusive timeless
                artistic creations. <br></br> <br></br> A constantly evolving
                experience, from the research of natural dyes to the combination
                of other techniques, such as the Japanese 'shibori' or
                'ecoprint'. <br></br> <br></br> The collaboration with the nuns
                of the Orsaminore Monastery in Orselina, where I make precious
                batiks on hand-spun and hand-woven silk, enriches the quality
                and sensitivity of the execution of my creations. <br></br>
                <br></br> In my atelier in Rancate, I create unique pieces of
                batik and works on request. <br></br> <br></br> I propose this
                fascinating technique through courses, for beginners, for
                teachers of creative activities, for high school students, both
                in my atelier and in spaces such as museums or within art
                events.
              </p>
            </div>

            <div className="swiper">
              <Swiper2
                imgs={[
                  "01_IKW_Biografia.webp",
                  "02_IKW_Biografia.webp",
                  "03_IKW_Biografia.webp",
                  "04_IKW_Biografia.webp",
                  "05_IKW_Biografia.webp",
                  "06_IKW_Biografia.webp",
                  "07_IKW_Biografia.webp",
                  "08_IKW_Biografia.webp",
                  "09_IKW_Biografia.webp",
                  "10_IKW_Biografia.webp",
                  "11_IKW_Biografia.webp",
                  "12_IKW_Biografia.webp",
                  "13_IKW_Biografia.webp",
                ]}
                delay={5000}
              />
            </div>
          </section>

          <section className="cms">
            <h2>ESPOSIZIONI</h2>
            {loaded && <PortableText value={homeContent.upcomingEvents} />}
            {/* <span className="expo">
              <h2>PASSATE</h2>
            </span>
            {loaded && <PortableText value={homeContent.passedEvents} />} */}
          </section>

          <section className="canali social">
            <h2>CANALI</h2>

            <a target="_blank" href="https://ikw.sumupstore.com/prodotti">
              Online shop
            </a>
            <a target="_blank" href="https://www.instagram.com/ikwaller/">
              Instagram
            </a>
            <a
              target="_blank"
              href="https://www.linkedin.com/in/isabella-k%C3%BCnzli-waller-00279111b/"
            >
              LinkedIn
            </a>

            <a target="_blank" href="https://www.pinterest.ch/wallerisabella/">
              Pinterest
            </a>

            <br></br>

            <a
              target="_blank"
              href="https://creattivati.ch/members/isabella-waller/"
            >
              Creattivati
            </a>
            <a
              target="_blank"
              href="https://www.homofaber.com/en/discover/discover-isabella-kunzli-waller"
            >
              Homofaber
            </a>
            <a
              target="_blank"
              href="https://metiersdart.ch/fr_CH/metiers-d-art/repertoire-artisan/kuenzli-waller-isabella"
            >
              Metierdart
            </a>
            <a
              target="_blank"
              href="https://www.aticrea.ch/Artigiano/isabella-kunzli-waller/"
            >
              Aticrea
            </a>
          </section>

          <section className="canali info">
            <h2>PER INFORMAZIONI</h2>
            <a target="_blank" href="mailto:waller.isabella@gmail.com">
              waller.isabella@gmail.com
            </a>
            <br></br>
            <a target="_blank" href="tel:+41796651321">
              +41 79 665 13 21
            </a>
            <br></br> <br></br>
            <div id="info">
              <div className="footer">
                <a target="_blank" href="https://goo.gl/maps/1fj3ybFLcV9hVfBFA">
                  Le bAArtelier <br></br>
                  Isabella Künzli Waller<br></br>
                  Via Caressaa 10<br></br>
                  6862 Rancate <br></br>
                </a>

                <img src={"./assets/logo.svg"} className="logo" />
              </div>
            </div>
          </section>
          {/* <img src={"./assets/gradient.svg"} /> */}
        </div>
      </div>
    </div>
  );
}

export default App;
